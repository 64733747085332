import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, Grid, Card, CardContent, CircularProgress, Button, } from '@mui/material';
import YogaIcon from '@mui/icons-material/HealthAndSafety';
import MeditationIcon from '@mui/icons-material/SelfImprovement';
import HealthIcon from '@mui/icons-material/EmojiNature';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import ReactMarkdown from 'react-markdown';
import './MainContent.css';

const getGreeting = () => {
  try {
    const date = new Date();
    const hour = date.getUTCHours() + 5.5;
    if (hour < 12) return "🌅 Good Morning";
    if (hour < 17) return "☀️ Good Afternoon";
    return "🌙 Good Evening";
  } catch (error) {
    console.error('Error calculating greeting:', error);
    return "🌟 Welcome";
  }
};

const InfoCard = React.memo(({ icon: Icon, title, description, onClick }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card onClick={onClick} className="info-card">
        <CardContent>
          <Icon className="info-card-icon" />
          <Typography variant="h6" className="info-card-title">{title}</Typography>
          <Typography variant="body2" className="info-card-description">{description}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
});

const MainContent = React.memo(({ messages, onCardClick, isActive, isGenerating }) => {
  const [greeting, setGreeting] = useState(getGreeting);
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 2000);
      },
      () => {
        setCopySuccess('Failed to copy!');
        setTimeout(() => setCopySuccess(''), 2000);
      }
    );
  };

  useEffect(() => {
    setGreeting(getGreeting());
  }, []);

  const handleCardClick = useCallback(
    (message) => {
      if (onCardClick) onCardClick(message);
    },
    [onCardClick]
  );

  const cardData = useMemo(
    () => [
      {
        icon: YogaIcon,
        title: 'Yoga Poses Guide',
        description: 'Explore various Yoga poses for flexibility, strength, and relaxation.',
        message: 'Explore various Yoga poses for flexibility, strength, and relaxation.',
      },
      {
        icon: MeditationIcon,
        title: 'Meditation Techniques',
        description: 'Discover meditation practices to reduce stress. ',
        message: 'Discover meditation practices to reduce stress and improve mindfulness.',
      },
      {
        icon: HealthIcon,
        title: 'Health and Wellness Tips',
        description: 'Tips on maintaining a balanced lifestyle through diet and exercise.',
        message: 'Tips on maintaining a balanced lifestyle through diet and exercise.',
        className:"3rd-icon"
      },
    ],
    []
  );

  return (
    <Box className="main-content">
      {messages.length > 0 ? (
        messages.map((msg, index) => (
          <Box key={index} className={`message-container ${msg.sender === 'user' ? 'user-message' : 'assistant-message'}`}>
            <Box className={`message-box ${msg.sender === 'user' ? 'user-message-box' : 'assistant-message-box'}`}>
              {msg.sender !== 'user' && !msg.isThinking && (
                <Button onClick={() => copyToClipboard(msg.text)} className="copy-button">
                  <ContentCopySharpIcon className="copy-icon" />
                </Button>
              )}

              {msg.isThinking ? (
                <Box className="thinking-box">
                  <CircularProgress size={20} />
                  <Typography>Thinking...</Typography>
                </Box>
              ) : msg.isMarkdown ? (
                <ReactMarkdown
                  components={{
                    h1: ({ node, ...props }) => (
                      <Typography variant="h4" gutterBottom {...props} />
                    ),
                    h2: ({ node, ...props }) => (
                      <Typography variant="h5" gutterBottom {...props} />
                    ),
                    h3: ({ node, ...props }) => (
                      <Typography variant="h6" gutterBottom {...props} />
                    ),
                    p: ({ node, ...props }) => (
                      <Typography variant="body1" paragraph {...props} />
                    ),
                    ul: ({ node, ...props }) => <ul className="markdown-list" {...props} />,
                    ol: ({ node, ...props }) => <ol className="markdown-list" {...props} />,
                  }}
                >
                  {msg.text}
                </ReactMarkdown>
              ) : (
                <Typography>{msg.text}</Typography>
              )}
            </Box>
          </Box>
        ))
      ) : (
        <Box className="welcome-container">
          <Typography variant="h2" className="welcome-title">
            {greeting}, Welcome to Llamaste Chat!
          </Typography>
          <Typography variant="h5" className="welcome-subtitle">
            I am your personal yoga assistant, Lamaste. How can I assist you today?
          </Typography>
          <Typography variant="h6" className="section-title">
            Yoga and Wellness Recommendations
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            {cardData.map((card, index) => (
              <InfoCard
                key={index}
                icon={card.icon}
                title={card.title}
                description={card.description}
                onClick={() => handleCardClick(card.message)}
              />
            ))}
          </Grid>

          <Box className="faq-container">
            <Typography variant="h6" className="section-title">
              Frequently Asked Questions (FAQ):
            </Typography>
            <Grid container spacing={3} justifyContent="center">
              {[
                "What are the benefits of Yoga?",
                "How often should I meditate?",
                "Can Yoga help with stress?",
              ].map((faq, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card onClick={() => handleCardClick(faq)} className="faq-card">
                    <CardContent>
                      <Typography variant="body1" className="faq-text">{faq}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
      {copySuccess && (
        <Typography className="copy-success-message">
          {copySuccess}
        </Typography>
      )}
    </Box>
  );
});

export default MainContent;