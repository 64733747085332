// input.js
import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField, Button, IconButton, Typography, CircularProgress, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { useNavigate } from 'react-router-dom';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import Person2Icon from '@mui/icons-material/Person2';
import { authService } from '../services/authService';
import './input.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ThinkingAnimation = () => (
  <Box className="thinking-animation">
    <Typography>Thinking</Typography>
    {[0, 1, 2].map((dot) => (
      <Box
        key={dot}
        className={`thinking-dot thinking-dot-${dot}`}
      />
    ))}
  </Box>
);

const Input = ({ onMessageUpdate, promptMessage, isLoading, setIsLoading, onSignOut }) => {
  const [inputData, setInputData] = useState({
    message: '',
    uploadedFileName: '',
    error: '',
  });

  const { message, uploadedFileName } = inputData;
  const [openToast, setOpenToast] = useState(false);
  const navigate = useNavigate();

  const setField = useCallback((field, value) => {
    setInputData((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleSignOut = useCallback(async () => {
    try {
      await authService.logout();
      onSignOut();
    } catch (error) {
      console.error('Error during logout:', error);
      onSignOut();
    }
  }, [onSignOut]);

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const handleSendClick = useCallback(async () => {
    if (!message.trim()) return;

    const displayMessage = (
      <>
        {uploadedFileName && (
          <Box className="uploaded-file-box">
            <InsertDriveFileRoundedIcon color='primary' />
            <Typography variant="body2" className="uploaded-file-name">{uploadedFileName}</Typography>
          </Box>
        )}
        <Box className="user-message-box">
          <Person2Icon color='primary' className="user-icon" />
          <Typography variant="body2" className="user-message">{message.trim()}</Typography>
        </Box>
      </>
    );
    const finalMessage = uploadedFileName
      ? `Message: ${message.trim()}\nFile: ${uploadedFileName}`
      : message.trim();

    onMessageUpdate((prevMessages) => [
      ...prevMessages,
      { text: displayMessage, sender: 'user' }
    ]);

    setIsLoading(true);
    setField('error', '');

    onMessageUpdate((prevMessages) => [
      ...prevMessages,
      { isThinking: true, sender: 'assistant', component: ThinkingAnimation }
    ]);

    try {
      const accessToken = localStorage.getItem('access_token');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/pdf/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ query: finalMessage }),
      });

      if (response.status === 403) {
        setOpenToast(true);
        setTimeout(() => {
          handleSignOut();
          navigate('/login');
        }, 5000);
        return;
      }

      if (!response.ok) throw new Error('Failed to fetch response from API');
      onMessageUpdate((prevMessages) => prevMessages.filter((msg) => !msg.isThinking));

      const assistantMessage = { text: '', sender: 'assistant', isMarkdown: true };
      onMessageUpdate((prevMessages) => [...prevMessages, assistantMessage]);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let currentText = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const cleanedChunk = chunk.replace(/^data:\s*/gm, '').trim();
        if (cleanedChunk) {
          currentText += cleanedChunk + '\n';

          // eslint-disable-next-line no-loop-func
          onMessageUpdate((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1].text = currentText;
            return updatedMessages;
          });
        }
      }
    } catch (error) {
      console.error('Error fetching assistant response:', error);
      setField('error', 'Failed to send message. Please try again later.');
      onMessageUpdate((prevMessages) => [
        ...prevMessages.filter((msg) => !msg.isThinking),
        {
          text: 'Error fetching response, please try again later.',
          sender: 'assistant',
          isError: true,
        }
      ]);
    } finally {
      setIsLoading(false);
      setField('message', '');
      setField('uploadedFileName', '');
    }
  }, [message, uploadedFileName, onMessageUpdate, setIsLoading, setField, handleSignOut, navigate]);

  const handleFileUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) setField('uploadedFileName', file.name);
  }, [setField]);

  useEffect(() => {
    if (promptMessage) setField('message', promptMessage);
  }, [promptMessage, setField]);

  return (
    <>
      <Box className="input-container">
        <IconButton
          component="label"
          className="upload-button"
        >
          <UploadFileRoundedIcon color="primary" className="upload-icon" />
          <input type="file" hidden onChange={handleFileUpload} />
        </IconButton>
        {uploadedFileName && (
          <Box className="uploaded-file-info">
            <Typography variant="body2" className="uploaded-file-text">
              {uploadedFileName}
            </Typography>
          </Box>
        )}

        <TextField
          fullWidth
          placeholder="Type your message here..."
          variant="outlined"
          value={message}
          onChange={(e) => setField('message', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && !isLoading && handleSendClick()}
          disabled={isLoading}
          className="message-input"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSendClick}
          disabled={isLoading}
          className="send-button"
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Send'}
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity="warning" className="toast-alert">
          Your token has expired. Redirecting to login page...
        </Alert>
      </Snackbar>
    </>
  );
};

export default React.memo(Input);