// login.js
import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    IconButton,
    Alert,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import yogaLoginImg from '../Assets/yoga_login.png';
import CloseIcon from '@mui/icons-material/Close';
import { authService } from '../services/authService';
import './login.css';
 
const Auth = ({ onLoginClick }) => {
    const [isLogin, setIsLogin] = useState(true);
    const [credentials, setCredentials] = useState({ name: '', email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});
 
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
 
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,}$/;
 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prev) => ({ ...prev, [name]: value }));
        validateField(name, value);
    };
 
    const validateField = (name, value) => {
        let fieldErrors = { ...errors };
   
        switch (name) {
            case 'name':
                if (!value.trim()) {
                    fieldErrors.name = 'Name is required.';
                } else if (value.trim().length < 2) {
                    fieldErrors.name = 'Name must be at least 2 characters.';
                } else {
                    delete fieldErrors.name;
                }
                break;
            case 'email':
                if (!value.trim()) {
                    fieldErrors.email = 'Email is required.';
                } else if (!emailRegex.test(value)) {
                    fieldErrors.email = 'Enter a valid email.';
                } else {
                    delete fieldErrors.email;
                }
                break;
            case 'password':
                if (!value) {
                    fieldErrors.password = 'Password is required.';
                } else if (!isLogin && !passwordRegex.test(value)) {
                    fieldErrors.password = 'Password must be at least 6 characters and include letters and numbers.';
                } else {
                    delete fieldErrors.password;
                }
                break;
            default:
                break;
        }
        setErrors(fieldErrors);
    };
   
    const validateForm = () => {
        let tempErrors = {};
        if (!isLogin) {
            if (!credentials.name.trim()) {
                tempErrors.name = 'Name is required.';
            } else if (credentials.name.trim().length < 2) {
                tempErrors.name = 'Name must be at least 2 characters.';
            }
            if (!credentials.password) {
                tempErrors.password = 'Password is required.';
            } else if (!passwordRegex.test(credentials.password)) {
                tempErrors.password = 'Password must be at least 6 characters and include letters and numbers.';
            }
        } else {
            if (!credentials.password) {
                tempErrors.password = 'Password is required.';
            }
        }
   
        if (!credentials.email.trim()) {
            tempErrors.email = 'Email is required.';
        } else if (!emailRegex.test(credentials.email)) {
            tempErrors.email = 'Enter a valid email.';
        }
   
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };
   
    const handleAuth = async () => {
        if (!validateForm()) {
            setError('Please fix the highlighted errors before submitting.');
            return;
        }

        try {
            if (isLogin) {
                await authService.login(credentials.email, credentials.password);
            } else {
                await authService.register(credentials);
            }
            setError('');
            onLoginClick();
        } catch (err) {
            setError(isLogin ? 'Invalid email or password. Please try again.' : 'Error signing up. Please check your details.');
            console.error('Authentication error:', err.message);
        }
    };
 
    const toggleLoginSignUp = () => {
        setIsLogin(!isLogin);
        setCredentials({ name: '', email: '', password: '' });
        setErrors({});
        setError('');
    };
 
    return (
        <Box className="auth-container">
            <Box className="image-container">
                <Box
                    component="img"
                    src={yogaLoginImg}
                    alt="Meditating figure"
                    className="yoga-image"
                />
                {['circle', 'square', 'diamond'].map((shape, index) => (
                    <Box
                        key={shape}
                        className={`shape ${shape}`}
                        style={{
                            top: `${20 + index * 30}%`,
                            left: `${10 + index * 20}%`,
                        }}
                    />
                ))}
            </Box>
            <Box className="form-container">
                {error && (
                    <Alert
                        severity="error"
                        className="error-alert"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => setError('')}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {error}
                    </Alert>
                )}
                <Typography variant="h3" component="h1" className="title">
                    Hey! Welcome to Llamaste
                </Typography>
                <Typography variant="h5" className="subtitle">
                    {isLogin ? 'Login please' : 'Sign Up'}
                </Typography>
                {!isLogin && (
                    <TextField
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={credentials.name}
                        onChange={handleInputChange}
                        className="input-field"
                        fullWidth
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />
                )}
                <TextField
                    label="Email"
                    variant="outlined"
                    name="email"
                    value={credentials.email}
                    onChange={handleInputChange}
                    className="input-field"
                    fullWidth
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={credentials.password}
                    onChange={handleInputChange}
                    className="input-field"
                    fullWidth
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                                aria-label={showPassword ? "Hide password" : "Show password"}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                />
                <Button
                    variant="contained"
                    onClick={handleAuth}
                    className={`auth-button ${Object.keys(errors).length > 0 ||
                        (!isLogin && !credentials.name.trim()) ||
                        !credentials.email.trim() ||
                        !credentials.password
                        ? 'disabled'
                        : ''}`}
                    disabled={Object.keys(errors).length > 0 ||
                        (!isLogin && !credentials.name.trim()) ||
                        !credentials.email.trim() ||
                        !credentials.password}
                >
                    {isLogin ? 'Login' : 'Sign Up'}
                </Button>
                <Typography variant="body2" className="toggle-text">
                    {isLogin ? "Don't have an account?" : "Already have an account?"}
                    <Typography
                        component="span"
                        onClick={toggleLoginSignUp}
                        className="toggle-link"
                    >
                        {isLogin ? ' Sign Up' : ' Login'}
                    </Typography>
                </Typography>
            </Box>
        </Box>
    );
};

export default Auth;