import React, { useEffect, useState, useCallback } from 'react';
import { AppBar, Toolbar, Typography, Button, Snackbar, IconButton, Alert, Menu, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { authService } from '../services/authService';
 
const Navbar = ({ isAuthenticated, username, onSignOut }) => {
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
 
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);
 
  const handleSignOut = useCallback(async () => {
    try {
      await authService.logout();
      onSignOut(); // This will be handled by the parent component
    } catch (error) {
      console.error('Error during logout:', error);
      onSignOut(); // Still sign out even if there's an error
    }
  }, [onSignOut]);
 
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
 
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
 
  const isMenuOpen = Boolean(anchorEl);
 
  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#1E88E5', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold', color: '#fff' }}>
            My Application
          </Typography>
          {isAuthenticated && (
            <>
              <Button
                color="inherit"
                onClick={handleMenuOpen}
                sx={{
                  '&:hover': { backgroundColor: '#1976D2' },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AccountCircleIcon sx={{ marginRight: 1 }} />
                {username}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
 
      {error && (
        <Snackbar open={Boolean(error)} autoHideDuration={5000} onClose={() => setError(null)}>
          <Alert
            onClose={() => setError(null)}
            severity="error"
            action={
              <IconButton size="small" onClick={() => setError(null)}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ width: '100%', position: 'absolute', top: 0, right: 0 }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
 
export default Navbar;