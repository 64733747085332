import React, { useState, useCallback, useMemo } from 'react';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Avatar,
  IconButton, Button, Divider, Typography, useMediaQuery
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import AssistantIcon from '@mui/icons-material/Assistant';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import { v4 as uuidv4 } from 'uuid';
import { motion, AnimatePresence } from 'framer-motion';
import llama from '../Assets/llama.jpeg';
import './Sidebar.css';

const Sidebar = ({ onAddSession, onSetActiveSession }) => {
  const [sessions, setSessions] = useState([{ id: 'default', name: 'Default Assistant', active: true }]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
  // Media query to detect small screens
  const isMobile = useMediaQuery('(max-width:600px)');

  const activeSessionId = useMemo(
    () => sessions.find(session => session.active)?.id || 'default',
    [sessions]
  );

  const handleAddSession = useCallback(() => {
    const newSession = { id: uuidv4(), name: `New Session ${sessions.length + 1}`, active: true };
    setSessions(prevSessions =>
      prevSessions.map(session => ({ ...session, active: false })).concat(newSession)
    );
    onSetActiveSession(newSession.id);
    onAddSession(newSession.id);
  }, [onAddSession, onSetActiveSession, sessions.length]);

  const handleDeleteSession = useCallback((id) => {
    setSessions(prevSessions => {
      const updatedSessions = prevSessions.filter(session => session.id !== id);
      if (id === activeSessionId && updatedSessions.length > 0) {
        onSetActiveSession(updatedSessions[0].id);
      }
      return updatedSessions;
    });
  }, [activeSessionId, onSetActiveSession]);

  const handleSetActiveSession = useCallback((id) => {
    setSessions(prevSessions => prevSessions.map(session => ({
      ...session, active: session.id === id
    })));
    onSetActiveSession(id);
    if (isMobile) setIsDrawerOpen(false); // Close drawer on mobile after selection
  }, [onSetActiveSession, isMobile]);

  return (
    <>
      {isMobile && (
        <IconButton
          onClick={() => setIsDrawerOpen(true)}
          className="menu-button"
        >
          <MenuIcon />
        </IconButton>
      )}

      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? isDrawerOpen : true}
        onClose={() => setIsDrawerOpen(false)}
        className="sidebar"
      >
        <Box className="sidebar-header">
          <Avatar
            alt="Model Icon"
            src={llama}
            className="sidebar-avatar"
          />
          <Typography variant="h5" className="sidebar-title">
            Llamaste
          </Typography>
        </Box>
        <Divider />
        <List className="sidebar-list">
          <AnimatePresence>
            {sessions.map(session => (
              <motion.div
                key={session.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <ListItem
                  button
                  selected={session.id === activeSessionId}
                  onClick={() => handleSetActiveSession(session.id)}
                  className={`sidebar-list-item ${session.id === activeSessionId ? 'active' : ''}`}
                >
                  <ListItemIcon>
                    {session.id === activeSessionId ? (
                      <AssistantIcon color="primary" />
                    ) : (
                      <ChatIcon color="action" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={session.name}
                    primaryTypographyProps={{
                      className: session.id === activeSessionId ? 'active-text' : '',
                    }}
                  />
                  {session.id !== 'default' && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSession(session.id);
                      }}
                      className="delete-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItem>
              </motion.div>
            ))}
          </AnimatePresence>
        </List>
        <Box className="sidebar-footer">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddSession}
            fullWidth
            className="add-session-button"
          >
            Add New Session
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default React.memo(Sidebar);
